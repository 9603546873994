import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../components/checkout';

import BackendCourseBackground from '../pics/backend-gif.gif'
import BackendCourseOverlay from '../pics/backend_course.jpg'

import Contacts from '../components/contactform';
import Covid from '../components/covid';
import Instalments from '../components/instalments.js'

const Backendcrashcourse = () => {

	let [courses, setCourses] = useState({
		durationdays: 5,
		durationweeks: 1,
		fulldesc1: " 1-week course, in-person, in English, full-time, Monday–Friday, 9:00 to 18:00  ",
		fulldesc2: "Registration payment 850€",
		fullprice: 850,
		image: "https://barcelonacodeschool.com/files/pics/backend_course.jpg",
		priceoptions: [],
		shortdesc: "Learn how to build servers and work with a database!",
		signupprice: 850,
		startdates: [ 'February 3, 2025','April 14, 2025','June 23, 2025'],
		starttime: "9:00",
		tag: "backEnd",
		title: "Back-end Development with Node, Express, MongoDB",
	})
	
	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/backEnd')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])

	let renderCourses =()=> {
		return courses.startdates.map((date,i)=>(
			<div key={i} className="whiteCard bottomSpace flex300" >
			<span>
			<h2>{date}</h2>
			<h4>{courses.title}</h4>
			<p>{courses.shortdesc}</p>
{date.includes("February 5, 2024")&& <h2 className='highlighter'>Group is full!</h2>}
			</span>
			<span>
			<p>{courses.fulldesc1}</p>
			<p>{courses.fulldesc2}</p>
			{date.includes("February 3, 2025")?<button>Group is full</button>:<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image], cryptoID: '51c46a8a-3bee-47ec-9cbc-42149b522ada' }}>
			<button style={{'marginBottom': '1em'}}>Sign up</button>
			</Link>}
			<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
			</span>
			</div>
			))
	}

	return <div className="flexContent"
	>
	<Helmet

	title="Back-end Development with Node, Express, MongoDB"
	meta={[
		{ name: 'description', content: 'Back-end Development with Node, Express, MongoDB Course in Barcelona Code School is a week-long intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners familiar with JavaScript. This course is a part of the Web / Mobile Development Bootcamp and could be taken separately as a module.' },
		{ name: 'keywords', content: 'programming with react course, react course, web development with react in barcelona' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
		{property: "og:title", content:"Back-end Development with Node, Express, MongoDB Course in Barcelona Code School"},
		{property: "og:description", content: "A week-long intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners familiar with JavaScript . This course is a part of the Web / Mobile Development Bootcamp and could be taken separately as a module."},
		{property: "og:image", content: "https://barcelonacodeschool.com/files/pics/backend_course.jpg"},
		{property: "og:url", content: "https://barcelonacodeschool.com/backend-development-with-node-express-mongodb/"},
		{name: "twitter:card", content: "summary_large_image"}
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" }
			]}
			/>

			<h1 className='transparentContainer flex1200'>Back-end Development with Node, Express, MongoDB</h1>

			<img
			className='banners flex1200 offsetImage'
			src={BackendCourseOverlay} 
			alt='Back-end Development with Node, Express, MongoDB Course in Barcelona Code School'/>

			<h2 className='transparentContainer flex450'>Learn how to build servers and work with a database!</h2>


			

			<div className='socialsharebuttons transparentContainer'>
			<div className='socialshare'>
			<a className="twitter-share-button "
			href="https://twitter.com/intent/tweet?text='Back-end Development with Node, Express, MongoDB Course in Barcelona Code School'&url=https://barcelonacodeschool.com/backend-development-with-node-express-mongodb/"
			data-size="large">
			<button>tweet</button>
			</a>
			</div>

			<div className="fb-share-button socialshare" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
			<a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/backend-development-with-node-express-mongodb/" className="fb-xfbml-parse-ignore">
			<button>facebook</button>
			</a>
			</div>
			</div>
			


			<article className='whiteCard flex300'>
			<ul>
			<li>Intensive in-person hands-on training</li>
			<li>1 week of hardcore back-end coding</li>
			<li>Daily classes 9:00 to 18:00</li>
			<li>The course is taught in English</li>
			<li>From zero to 👸 and 🤴</li>
			<li>15% theory / 85% practice / 100% efficiency</li>
			</ul>
			</article>



			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul>
			<li>Overall concept</li>
			<li>Set up</li>
			<li>Exporting and importing</li>
			<li>Working with npm</li>
			<li>Structuring your server</li>
			<li>Routes and controllers</li>
			<li>Passing data via params and body</li>
			<li>HTTP requests</li>
			<li>Serving static content</li>
			<li>HTTP Status Codes</li>
			<li>Using Postman for a development</li>
			<li>Persistent storage with MongoDB</li>
			<li>Runing MongoDB locally and remotely</li>
			<li>Mongoose</li>
			<li>Handling errors</li>
			<li>Deployment</li>
			</ul>
			</div>
			</article>




			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<p>Solid understanding of JavaScript logic and syntax; functions, loops, conditionals, arrays, objects.</p>
			</div>
			</article>


			<article className='transparentContainer flex225'>
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<p>After this course you will have a strong understanding of the <strong>logic</strong>, <strong>syntax</strong> and <strong>usage</strong> of Express and back-ends in general. </p>
			<p>You will be able to build Express servers from scratch and have progress further into the depths of back-end development.</p>
			</div>
			</article>



			<article className='transparentContainer flex225'>
			<h2>A Wise quote</h2>
			<div className='whiteCard'>
			<p>"Only half of programming is coding. The other 90% is debugging." </p>
			<p>– Unknown</p>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>850€</h2>
			<p>100% is paid upon registration.</p>
			<Instalments/>
			</div>
			</article>


			<article className="transparentContainer flex900 flexContent">
			<h2>Access</h2>
			<div className='flexContent'>
			{renderCourses()}
			</div>
			</article>

			<article className='flex450'>
			<Contacts title={'Inquiry about the course'} subject={'Inquiry about Back-end Development with Node, Express, MongoDB'} />
			</article>

			</div>

		}

		export default Backendcrashcourse
